html,
body,
#root,
.mainContainer {
  margin: 0px;
  padding: 0px;
  height: 100% !important;
  width: 100% !important;
  border: none !important;
}

@media screen and (max-width: 720px) {
  .mainContainer {
    background-image: none !important;
  }
}

.mainContainer {
  background-image: url(./components/images/bg.svg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  background-attachment: scroll;
  height: 100vh !important;
  width: 100%;
}

.my-app {
  overflow: auto;
}

.hero-side {
  height: 80%;
  width: 100%;
}

.heyThere {
  height: 80%;
  justify-content: center;
  align-items: center;
}

a {
  text-decoration: none;
  color: white;
}
/* .vol {
  height: 20%;
} */

/* .img-sec {
  background-image: url(./components/images/char.svg);
  background-repeat: no-repeat;
} */

/* max-width constrains the width of our carousel to 550, but shrinks on small devices */

/* max-width constrains the width of our carousel to 550, but shrinks on small devices */
.styles-module_carousel-base__3keqD {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  padding-bottom: 0px;
  overflow-y: visible;
}

.styles-module_carousel-arrow__26sRw {
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.styles-module_carousel-arrow__26sRw.left {
  background-image: url(./components/images/back.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.styles-module_carousel-arrow__26sRw.right {
  background-image: url(./components/images/forward.svg);
  background-repeat: no-repeat;
  background-position: center;
}
